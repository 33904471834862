<template>
  <div style="height: 100%;">
    <SpaceAppBar :spaceUri="spaceUri" />
    <v-toolbar
      flat
      dense
      class="toolbar"
      v-if="this.$vuetify.breakpoint.mobile"
    >
      <v-btn
        class="collapse-button"
        @click="showNavigationList = !showNavigationList"
        color="accent"
        text
        small
      >
        <v-icon v-if="showNavigationList">mdi-chevron-double-left</v-icon>
        <v-icon  v-else>mdi-chevron-double-right</v-icon>
      </v-btn>
    </v-toolbar>
    <div
      class="d-flex flex-row"
      style="height: 100%; background-color: white;"
    >
      <div
        class="navigation-list"
        :class="{collapsed: !showNavigationList}"
        v-if="hasBlocks"
      >
        <div class="d-flex align-center px-3">
          <v-subheader class="pl-0 pr-3">{{ $t('blocks.pages') }}</v-subheader>
          <v-spacer />
          <v-btn
            v-if="canAddPage"
            small
            icon
            @click="addPage"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>
        <v-list>
          <v-list-item
            v-for="(block, index) in blocks"
            :key="index"
            :data-testid="`blockPageItem-${index}`"
            :to="{ name: 'BlockPage', params: { blockUri: block.uri } }"
          >
            <v-list-item-title>
              <EditableText
                :text="blockName(block, index)"
                @submit="newName => patchName(block, newName)"
                :readonly="!(block instanceof PageBlock)"
              />
            </v-list-item-title>
            <v-list-item-action>
              <PageBlockMenu
                v-if="canPatchBlock(block) || canDeleteBlock(block)"
                :block="block"
                @blockDeleted="() => blockDeleted(block)"
              />
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </div>
        
      <v-progress-circular
        indeterminate
        v-if="loading"
        color="primary"
        width="2"
        size="36"
        class="loader"
      />
        <router-view v-else-if="hasBlocks" />

        <v-container v-else>
          <div class="d-flex flex-column align-center justify-center mt-12">
           <v-img  src="/blocks_empty_space.svg"></v-img>
           <div class="text-h6">
               {{ $t("blocks.emptyStateBlocksHeader") }}
           </div>
           <div style="max-width: 420px;" class="text-center accent--text mt-2">
               {{ $t("blocks.emptyStateBlocksDescription") }}
           </div>
           <v-btn
             :disabled="!canAddPage"
             data-testid="addPageButtonEmptyState"
             color="primary"
             class="mt-6"
             @click="addPage"
           >
               {{ $t("blocks.emptyStateBlocksAddPage") }}</v-btn>
          </div>
        </v-container>
        </div>
  </div>
</template>

<script>
import EditableText from '@/components/EditableText.vue'
import PageBlockMenu from '@/components/block/PageBlockMenu.vue'
import { blockTypes } from '@/constants/blockTypes'
import { HEADER_LEVELS } from '@/store/models/blocks/HeaderBlock'
import PageBlock from '@/store/models/blocks/PageBlock'
import blockEditorBus from '@/utils/blockEditorBus'
import { hasPermission, PERMISSIONS } from '@/utils/halUtils'
import SpaceAppBar from '@/views/SpaceAppBar.vue'

export default {
  props: {
    spaceUri: null,
  },
  data() {
    return {
      loading: false,
      blocks: [],
      showNavigationList: true,
      PageBlock
    }
  },
  mounted() {
    blockEditorBus.onPageNameUpdated(this.listBlocks)
  },
  beforeDestroy() {
    blockEditorBus.pageNameUpdatedOff(this.listBlocks)
  },
  watch: {
    spaceUri: {
      immediate: true,
      async handler(newVal) {
        this.loading = true
        try {
          await this.$store.dispatch('AGReadSpaceOperation', {
            spaceUri: newVal
          })
          await this.listBlocks()
          if ( this.$route.name === 'BlockEditor' ) {
            this.initialNavigation()
          }
        } finally {
          this.loading = false
        }
      }
    }
  },
  computed: {
    space() {
      return this.$store.getters.spaceWithUri(this.spaceUri)
    },
    hasBlocks() {
      return this.blocks.length > 0
    },
    canAddPage() {
      return hasPermission(this.space, [PERMISSIONS.addBlock])
    }
  },
  methods: {
    canPatchBlock(block) {
      return hasPermission(block, [PERMISSIONS.patch])
    },
    canDeleteBlock(block) {
      return hasPermission(block, [PERMISSIONS.remove])
    },
    initialNavigation() {
      if (this.blocks.length >= 1) {
        this.$router.replace({ name: 'BlockPage', params: { blockUri: this.blocks[ 0 ].uri } })
      }
      else if (this.$route.name !== 'BlockEditor') {
        // once the last blockpage was deleted, switch to the BlockEditor route
        this.$router.replace({ name: 'BlockEditor'})
      }
    },
    async listBlocks() {
        this.blocks = await this.$store.dispatch('AGListSpaceBlocks', this.space)
    },
    async addPage() {
      this.loading = true
      try {
        const response = await this.$store.dispatch('AGAddSpacePageBlock', this.space)
        const newBlock = await this.$store.dispatch('AGReadBlock', { blockUri:  response.headers.location })
        await this.$store.dispatch('AGAddBlockChild', {
          block: newBlock,
          type: blockTypes.header1.type,
          position: 1,
          blockProps: {
            header: {
              level: HEADER_LEVELS[1].backendName
            }
          }
        })
        await this.listBlocks()
        this.$router.push({ name: 'BlockPage', params: { blockUri: response.headers.location } }) 
      } finally {
        this.loading = false
      }
    },
    blockName(block, index) {
      return block.name || `${this.$t('blocks.emptyPageTitle')} ${index + 1}`
    },
    async patchName(block, newName) {
      await block.patchName(newName)
      block.name = newName
    },
    async blockDeleted(block) {
      await this.listBlocks()
      if (this.$route.name === 'BlockPage' && this.$route.params.blockUri === block.uri) {
        this.initialNavigation()
      }
    }
  },
  components: {
    // BlockPage,
    EditableText,
    PageBlockMenu,
    SpaceAppBar
  }
}
</script>

<style
  lang="scss"
  scoped
>
.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 3;
}
.navigation-list {
  background-color: white;
  border-right: 1px solid #e4e5e4;
  width: 240px;
  flex-shrink: 0;
  max-width: 300px;
  overflow: auto;
  transition: width 0.2s;
}

.navigation-list.collapsed {
  width: 0px;
  border: none;
}

.toolbar {
  z-index: var(--grid-toolbar-zindex);
  border-style: none none solid none;
  border-width: 1px;
  border-color: #d3d2d4;
  background-color: white;
}
</style>