import Vue from 'vue'
import App from './App.vue'
import store from './store/store.js'
import router from './router'
import { initializeVuetify } from '@/plugins/vuetify' // Adjust the path if needed
import feature from '@/plugins/feature'
import i18n from '@/plugins/i18n' // path to vuetify export
import $apptive from './$apptive.js'
import apptiveErrorReporter from '@/plugins/apptiveErrorReporter.js'

Vue.config.productionTip = false

document.documentElement.setAttribute('lang', i18n.locale)
initializeApp()
feature.initialize()

Vue.prototype.$apptive = $apptive

Vue.prototype.$apptiveErrorReporter = apptiveErrorReporter
Vue.config.errorHandler = (error) => apptiveErrorReporter.captureException(error)

// Function to load the configuration from the web
async function loadConfig() {
  try {
    const response = await fetch('/config.json')
    if (!response.ok) {
      throw new Error(`Failed to fetch config: ${response.statusText}`)
    }
    const config = await response.json()

    return config
  } catch (error) {
    console.error('Failed to load config:', error)
    return {} // Return an empty object as fallback
  }
}

// Initialize the app after loading the config
async function initializeApp() {
  const config = await loadConfig()
  Vue.prototype.$settings = config // Make the settings globally accessible

  const vuetify = initializeVuetify(config)

  new Vue({
    i18n,
    vuetify,
    router,
    store: store(),
    render: h => h(App)
  }).$mount('#app')
}

