import i18n from '@/plugins/i18n'
import { HEADER_LEVELS } from '@/store/models/blocks/HeaderBlock'


// Define blockTypes as a plain object with properties for each type
export const blockTypes = {
  paragraph: {
    type: 'paragraph',
    displayTitleString: i18n.t('blockTypes.paragraph.title'),
    displaySubtitleString: i18n.t('blockTypes.paragraph.subtitle'),
    icon: 'mdi-text-long',
  },
  header1: {
    type: 'header',
    displayTitleString: i18n.t('blockTypes.header1.title'),
    displaySubtitleString: i18n.t('blockTypes.header1.subtitle'),
    icon: 'mdi-format-header-1',
    defaultProps: {
      level: HEADER_LEVELS[1].backendName
    }
  },
  header2: {
    type: 'header',
    displayTitleString: i18n.t('blockTypes.header2.title'),
    displaySubtitleString: i18n.t('blockTypes.header2.subtitle'),
    icon: 'mdi-format-header-2',
    defaultProps: {
      level: HEADER_LEVELS[2].backendName
    }
  },
  header3: {
    type: 'header',
    displayTitleString: i18n.t('blockTypes.header3.title'),
    displaySubtitleString: i18n.t('blockTypes.header3.subtitle'),
    icon: 'mdi-format-header-3',
    defaultProps: {
      level: HEADER_LEVELS[3].backendName
    }
  },
  string: {
    type: 'string',
    displayTitleString: i18n.t('blockTypes.text'),
    icon: 'mdi-alphabetical-variant',
  },
  grid: {
    type: 'grid',
    displayTitleString: i18n.t('blockTypes.grid'),
    icon: 'mdi-grid-large',
  },
  dateandtime: {
    type: 'dateAndTimeInput',
    displayTitleString: i18n.t('blockTypes.dateTime'),
    icon: 'mdi-calendar-clock',
  },
  dictionary: {
    type: 'dictionary',
    displayTitleString: i18n.t('blockTypes.dictionary'),
    icon: 'mdi-book-open-page-variant',
  },
  attachmentcollection: {
    type: 'attachmentCollectionInput',
    displayTitleString: i18n.t('blockTypes.attachmentCollection'),
    icon: 'mdi-paperclip',
  },
  uri: {
    type: 'uriInput',
    displayTitleString: i18n.t('blockTypes.uri'),
    icon: 'mdi-web',
  },
  decimal: {
    type: 'decimalInput',
    displayTitleString: i18n.t('blockTypes.decimal'),
    icon: 'mdi-decimal',
  },
  mimeentitycollection: {
    type: 'mimeentitycollection',
    displayTitleString: i18n.t('blockTypes.mimeEntityCollection'),
    icon: 'mdi-email-multiple',
  },
  entity: {
    type: 'entityInput',
    displayTitleString: i18n.t('blockTypes.entity'),
    icon: 'mdi-account-box',
  },
  integer: {
    type: 'integerInput',
    displayTitleString: i18n.t('blockTypes.integer'),
    icon: 'mdi-numeric',
  },
  mimeentity: {
    type: 'mimeentity',
    displayTitleString: i18n.t('blockTypes.mimeEntity'),
    icon: 'mdi-email',
  },
  enumcollection: {
    type: 'enumCollectionInput',
    displayTitleString: i18n.t('blockTypes.enumCollection'),
    icon: 'mdi-format-list-bulleted',
  },
  user: {
    type: 'userInput',
    displayTitleString: i18n.t('blockTypes.user'),
    icon: 'mdi-account',
  },
  location: {
    type: 'geoLocationInput',
    displayTitleString: i18n.t('blockTypes.location'),
    icon: 'mdi-map-marker',
  },
  boolean: {
    type: 'booleanInput',
    displayTitleString: i18n.t('blockTypes.boolean'),
    icon: 'mdi-toggle-switch',
  },
  currency: {
    type: 'currencyInput',
    displayTitleString: i18n.t('blockTypes.currency'),
    icon: 'mdi-currency-usd',
  },
  date: {
    type: 'dateInput',
    displayTitleString: i18n.t('blockTypes.date'),
    icon: 'mdi-calendar',
  },
  multitext: {
    type: 'multitext',
    displayTitleString: i18n.t('blockTypes.multiText'),
    icon: 'mdi-text-box-multiple',
  },
  address: {
    type: 'addressInput',
    displayTitleString: i18n.t('blockTypes.address'),
    icon: 'mdi-home-city',
  },
  enum: {
    type: 'enumInput',
    displayTitleString: i18n.t('blockTypes.enum'),
    icon: 'mdi-format-list-bulleted-type',
  },
  text: {
    type: 'textInput',
    displayTitleString: i18n.t('blockTypes.text'),
    icon: 'mdi-text',
  },
  entitycollection: {
    type: 'entitycollectionInput',
    displayTitleString: i18n.t('blockTypes.entityCollection'),
    icon: 'mdi-account-multiple',
  },
  signature: {
    type: 'signatureInput',
    displayTitleString: i18n.t('blockTypes.signature'),
    icon: 'mdi-pen',
  },
  email: {
    type: 'emailInput',
    displayTitleString: i18n.t('blockTypes.email'),
    icon: 'mdi-email',
  },
  phonenumber: {
    type: 'phoneNumberInput',
    displayTitleString: i18n.t('blockTypes.phoneNumber'),
    icon: 'mdi-phone',
  },
  attachment: {
    type: 'attachmentInput',
    displayTitleString: i18n.t('blockTypes.attachment'),
    icon: 'mdi-attachment',
  },
  resource: {
    type: 'resourceInput',
    displayTitleString: i18n.t('blockTypes.resource'),
    icon: 'mdi-link-variant',
  },
  block: {
    type: 'block',
    displayTitleString: i18n.t('blockTypes.block'),
    icon: 'mdi-file-document-outline',
  },
  page: {
    type: 'page',
    displayTitleString: i18n.t('blockTypes.page'),
    icon: 'mdi-file-document-outline',
  }
}
