import store from '../store'
import CardViewBase from './CardViewBase'
import { columnTypes } from '@/constants/columnTypes.js'
import { 
  GALLERY_CARD_POSITION, 
  GALLERY_CARD_IMAGE_MODE, 
  GALLERY_CARD_SIZE , 
  GALLERY_CARD_SIZE_SMALL,
  GALLERY_CARD_IMAGE_MODE_FIRST,
  GALLERY_CARD_HIDE_FIELD_TYPE_ICON,
  GALLERY_CARD_HIDE_FIELD_NAME } from '@/constants/galleryCardTypes.js'

export default class GalleryView extends CardViewBase {
  constructor(data) {
    super(data)
    this.timeoutMap = { }
  }

  get cardPositionKey() {
    return GALLERY_CARD_POSITION
  }

  reload() {
    this.timeoutMap = { }
    return store().dispatch('AGReadStatefulViewOperation', this.uri)
  }

  shouldLoadEntity(entity, grid) {
    // called one a card is mouned and the entity is not loaded. 
    // load page that might contain that entity 
    let pageIndex = entity.pageIndex
    if (this.timeoutMap[pageIndex]) {
      // a call was scheduled for this pageId
    }
    else {
      // no call scheduled for this page
      const timeoutId = setTimeout( () => grid.entitiesPager.loadPage(entity.pageIndex) , 1000 )
      this.timeoutMap[pageIndex] = timeoutId
    }
  }

  get imageMode() {
    return this.properties[GALLERY_CARD_IMAGE_MODE] 
  }

  set imageMode(newVal) {
    this.properties[GALLERY_CARD_IMAGE_MODE] =  newVal
  }

  get cardSize() {
    return this.properties[GALLERY_CARD_SIZE] || GALLERY_CARD_SIZE_SMALL
  }

  set cardSize(newVal) {
    this.properties[GALLERY_CARD_SIZE] =  newVal
  }

  get hideTypeIcon() {
    return this.properties[GALLERY_CARD_HIDE_FIELD_TYPE_ICON] || false
  }

  set hideTypeIcon(newVal) {
    this.properties[GALLERY_CARD_HIDE_FIELD_TYPE_ICON] =  newVal
  }

  get hideFieldName() {
    return this.properties[GALLERY_CARD_HIDE_FIELD_NAME] || false
  }

  set hideFieldName(newVal) {
    this.properties[GALLERY_CARD_HIDE_FIELD_NAME] =  newVal
  }

  // Function to create a 2D array of card models for a given grid and items per row
  // Input: grid - an object containing the entitiesPager and entities arrays, itemsPerRow - number of items to display per row
  cardsModel(grid, itemsPerRow) {
    // Ensure at least one card per row
    itemsPerRow = Math.max(itemsPerRow, 1)

    // Get the total number of items and page size from the entitiesPager object
    let numberOfItems = grid.entitiesPager?.numberOfItems || 0
    let pageSize = grid.entitiesPager?.size || 1

    // Create an array to store the rows of card models
    const rows = []

    // Loop through the items in the entities array, creating rows of card models
    for (let i = 0; i < numberOfItems; i += itemsPerRow) {
        const row = []

        // Loop through the items in the current row, creating a card model for each item
        for (let j = i; j < i + itemsPerRow && j < numberOfItems; j++) {

            // Calculate the page index for the current item using the page size
            let pageIndex = Math.ceil((j + 1)  / pageSize)

            // Create a card model for the current item and add it to the current row
            row.push( this.cardModel(grid.entities[j], grid, pageIndex) )
        }

        // Add the current row of card models to the rows array
        rows.push(row)
    }

    // Return the 2D array of card models
    return rows
  }


  cardModel(entity, grid, pageIndex) {
    
    const attachmentsIndex = grid.fields.findIndex(
      field => field.hasOneOfTypes([columnTypes.attachments])
    )
  
    const getAttribute = (entityField, index) => ({
      key: grid.fields[index]?.name,
      value: entityField ? grid.displayFormat(entityField, index) : null,
      fieldValue: entityField,
      fieldId:  grid.fields[index]?.id,
      property: grid.fields[index]?.columnType,
    })

    const getPlaceholderAttribute = (gridField, index) => ({
      key: grid.fields[index]?.name,
      value: null,
      fieldValue: null,
      fieldId:  grid.fields[index]?.id,
      property: grid.fields[index]?.columnType,
    })
  
    const attributes = entity
      ? entity.fields.map(getAttribute).filter( attr => attr.key )
      : grid.fields.map(getPlaceholderAttribute).filter( attr => attr.key )
  
    return {
      entity: entity || null,
      attachments: (entity && this.showImage()) ? entity.fields[attachmentsIndex] : null,
      attributes: attributes.filter( attr => this.fieldIsShown( attr.fieldId )).sort( (a, b) => this.fieldPostion(a.fieldId) - this.fieldPostion(b.fieldId)),
      pageIndex: pageIndex
    }
  }

  showImage() {
    return this.imageMode == GALLERY_CARD_IMAGE_MODE_FIRST
  }

}