import Vue from 'vue'
const bus = new Vue()

const PAGE_NAME_UPDATED = 'pageNameUpdated'

export default {
  pageNameUpdated() {
    bus.$emit(PAGE_NAME_UPDATED)
  },
  onPageNameUpdated(handler) {
    bus.$on(PAGE_NAME_UPDATED, handler)
  },
  pageNameUpdatedOff(handler) {
    bus.$off(PAGE_NAME_UPDATED, handler)
  },
}
