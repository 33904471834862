<template>
  <v-menu
    ref="menu"
    v-model="externalModel"
    offset-x
    max-width="400px"
    min-width="400px"
    max-height="80vh"
    :close-on-content-click="false"
    v-bind="$attrs"
  >
    <template v-slot:activator="{ on }">
      <slot name="activator" :on="on"/>
    </template>
    <v-card class="pb-3 d-flex flex-column variable-picker-body">
    
      <div v-if="pickerValue" class="overflow-auto">
        <v-tabs
        dense
        v-model="tab"
        color="primary"
        background-color="white"
        slider-color="primary"
      >
      <v-tab><v-icon dense >mdi-code-braces</v-icon></v-tab>
      <v-tab><v-icon dense>mdi-account-outline</v-icon></v-tab>
      <v-spacer/>
        
      <v-btn
          icon
          class="align-self-center mr-2"
          @click="externalModel = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>

      <v-tab-item>
        <div class="ma-3 ">

        <v-text-field
          v-model="search"
          @blur="searchFocused = false"
          @focus="searchFocused = true"
          prepend-inner-icon="mdi-magnify"
          :label="$t('flow.variablePicker.search')"
          dense 
          :solo-inverted="!searchFocused" 
          :solo="searchFocused" 
          :flat="!searchFocused"
          color="primary"
          hide-details
          clearable
          clear-icon="mdi-close-circle-outline"
          data-testid="variablePickerSearchField"
        ></v-text-field>

        <div class="subtitle-2 mt-2">{{ $t('flow.variablePicker.title') }}</div>

          <FlowTreeView
            class="mt-2 ml-4"
            draggable
            :value="pickerValue"
            :flow="flow"
            :filterNode="filterNode"
            :search="search"
            :openAll="searchFieldActive"
            @itemClicked="onItemClicked"
            @itemDragged="onTokenDragStart"
            @update:open="activate"
          />
        </div>
      </v-tab-item>

      <v-tab-item>
        <div class="ma-3">
          <div class="subtitle-2 mb-2">{{$t('flow.variablePicker.flowVariables')}}</div>
          <Token
                  v-for="item in globalExpressionItems"
                  class="mx-1 mb-1"
                  :key="item.key"
                  :text="item.text"
                  :value="item.value"
                  :color="item.color"
                  :draggable="true"
                  @dragstart.native="() => onTokenDragStart({expression: () => item.value})"
                  @click.native="onItemClicked({expression: () => item.value})"
                />
        </div>

      </v-tab-item>
    </v-tabs>
      </div>
      <div class="pa-3 d-flex flex-column" v-else>
        <v-btn
          icon
          class="align-self-end mr-2"
          @click="externalModel = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <div class="text-center text-h6">{{$t('flow.variablePicker.headerEmpty')}}</div>
        <div class="text-center text-body-1">{{$t('flow.variablePicker.descriptionEmpty')}}</div>
        <FlowTestRunner
          class="mt-4 align-self-center"
          :flow="flow"
        />
      </div>
    </v-card>
  </v-menu>
</template>

<script>
import FlowTreeView from '@/components/flow/FlowTreeView.vue'
import externalModel from '../../mixins/externalModel'
import FlowTestRunner from '@/components/flow/FlowTestRunner.vue'
import Token from './Token.vue'
import { AGOwnerEmailExpression, AGOwnerFirstNameExpression, AGOwnerLastNameExpression } from '@/constants/expressions/index.js'
import variablePickerBus from '@/components/flow/variablePickerBus'

export default {
  mixins: [externalModel],
  props: ['pickerValue', 'flow', 'filterNode'],
  data() {
    return {
      menu: false,
      globalsPanels: [],
      globalExpressions: [
        AGOwnerEmailExpression,
        AGOwnerFirstNameExpression,
        AGOwnerLastNameExpression,
      ],
      tab: 0,
      search: null,
      searchFocused: false
    }
  },
  computed: {
    globalExpressionItems() {
      return this.globalExpressions.map(expression => {
        const instance = new expression()
        return {
          key: instance.exportValue(),
          text: instance.displayString(),
          value: instance.exportValue(),
          color: instance.color()
        }
      })
    },
    searchFieldActive() {
      return this.search ? this.search.length > 0 : false
    }
  },
  methods: {
    onItemClicked(item) {
      this.$emit('pickerInput', item.expression())
    },
    onTokenDragStart(item) {
      variablePickerBus.tokenDragStart(item.expression())
    },
    activate() {
      this.$refs.menu.activate()
    }
  },
  components: {
    FlowTreeView,
    FlowTestRunner,
    Token
  }
}
</script>

<style scoped>
.variable-picker-body {
  max-height: 80vh;
}
</style>
