import ApiClient from '@/ApiClient'
import { createBlock } from '@/store/models/blocks'
import { PERMISSIONS } from '@/utils/halUtils'

export default {
  actions: {
    async AGListSpaceBlocks(context, space) {
      const link = space.getLink('blocks')
      const response = await ApiClient.getUri(link)
      return response.data.items.map(item => createBlock(item))
    },
    AGAddSpacePageBlock(context, space) {
      const link = space.getLink(PERMISSIONS.addBlock)
      return ApiClient.postUri(link, {
        type: 'page'
      })
    },
    async AGReadBlock(context,{ blockUri , customErrorHandling }) {
      const response = await ApiClient.getUri(blockUri, undefined, {customErrorHandling})
      return createBlock(response.data)
    },
    AGDeleteBlock(context, block) {
      const link = block.getLink(PERMISSIONS.remove)
      return ApiClient.deleteUri(link)
    },
    AGPatchBlock(context, {block, payload}) {
      const link = block.getLink(PERMISSIONS.patch)
      return ApiClient.patchUri(link, payload)
    },
    AGUpdateBlock(context, {block, payload}) {
      const link = block.getLink(PERMISSIONS.update)
      return ApiClient.putUri(link, payload)
    },
    AGAddBlockChild(context, {block, type, position, blockProps }) {
      const link = block.getLink(PERMISSIONS.addChild)
      return ApiClient.postUri(link, {
        type: type,
        position: position,
        ...blockProps
      })
    }
  }
}