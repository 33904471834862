<template>
  <div :data-testid="`input_${label}`">
    <div
      v-if="topLabel"
      class="v-label theme--light mb-1 text-caption top-label"
      :class="{invisible: !showTopLabel}"
    >{{label}}</div>
    <div class="template-input-wrapper" :data-testid="`input_${$attrs.testKey}`">
      <div class="label v-label theme--light" v-show="showLabel">{{label}}</div>
      <div ref="hiddenContent" class="hidden-content">
        <p>
          <template v-for="(section, index) in sections">
            <template v-if="typeof section === 'object'" >
              <Token
                :key="`section-${index}`"
                :text="section.text"
                :value="section.value"
                :color="section.color"
              />
              <span :key="`space-${index}`"></span>
            </template>
            <span
              v-else
              ref="sections"
              :draggable="false"
              v-text="section"
              :key="`section-${index}`"
            />
          </template>
        </p>
      </div>
      <div ref="input" class="input" :style="inputStyle" :class="{textArea}">
      </div>

      <v-menu
        v-model="pathMenu"
        :position-x="pathMenuX"
        :position-y="pathMenuY"
      >
        <v-sheet class="primary--text pa-3">{{ path }}</v-sheet>
      </v-menu>
  
      <v-tooltip
        v-if="hasError"
        open-delay="400"
        top
        content-class="error-tooltip"
      >
        <template v-slot:activator="{ on }">
            <v-icon
              class="error-icon"
              v-on="on"
              color="error"
              size="22"
            >mdi-alert-circle-outline</v-icon>
        </template>
        <span>{{error}}</span>
      </v-tooltip>
    </div>
  </div>
</template>


<script>
/* global ASParser */
import Token from './Token.vue'
import externalModel from '@/mixins/externalModel.js'
import Editor, { tokenPropsFromString, isExpression } from '@/editor/Editor.js'
import { PathDisplayVisitor } from '../../apptivescript/apptivescriptUtils'
import variablePickerBus from '@/components/flow/variablePickerBus'
import { ASHolder } from '@/apptivescript/model/ASHolder'
import { holderFromString } from '@/apptivescript/model'
import ASScriptHolder from '@/apptivescript/model/ASScriptHolder'


export default {
  mixins: [externalModel],
  props: {
    flow: null,
    textArea: {
      type: Boolean,
      default: () => false
    },
    selected: {
      type: Boolean,
      default: () => false
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    topLabel: {
      type: Boolean,
      default: () => false
    },
    error: {
      type: String,
      default: () => null
    },
    label: null,
    blockType: null
  },
  data() {
    return {
      editor: undefined,
      textSchema: undefined,
      sections: [],
      focused: false,

      pathMenu: false,
      pathMenuX: undefined,
      pathMenuY: undefined,
      path: undefined,
      pathMenuTimeout: undefined
    }
  },
  computed: {
    borderColor() {
      if (this.hasError) {
        return this.$vuetify.theme.themes.light.error
      }
      return this.selected ? this.$vuetify.theme.themes.light.primary : '#D3D3D3'
    },
    inputStyle() {
      return {
        '--border-color': this.borderColor
      }
    },
    showLabel() {
      return (this.value == null || this.value.length === 0) && this.label && !this.focused
    },
    showTopLabel() {
      return this.topLabel && this.focused
    },
    hasError() {
      return this.error != null
    }
  },
  mounted() {
    const editorViewNode = this.$refs.input
    const contentNode = this.$refs.hiddenContent

    const domEventHandlers = {
      blur: (editor) => {
        this.focused = false
        this.emitEditorContent(editor)
      },
      focus: () => {
        this.$emit('focus')
        this.focused = true
      },
      pointerover: (editorView, event) => {
        const nodePosition = editorView.posAtCoords({left: event.clientX, top: event.clientY})
        if (nodePosition.inside <= 0) {
          this.hidePath()
          return
        }
        const node = editorView.nodeDOM(nodePosition.inside)
        const nodeValue = node?.getAttribute('data-value')
        if (nodeValue != null) {
          const expression = nodeValue.match(/{{(.+?)}}/)[1]
          this.showPath(event, expression)
        }
      },
      drop: (editorView, event) => {
        const expression = variablePickerBus.getDraggedExpression()
        if (expression == null) {
          return
        }
        const nodePosition = editorView.posAtCoords({left: event.clientX, top: event.clientY})
        if (nodePosition == null) {
          return
        }
        this.editor.insertExpressionAt(expression, nodePosition)
        this.emitEditorContent()
      },
      pointerleave: this.hidePath
    }

    this.editor = new Editor({
      flow: this.flow,
      editorViewNode,
      contentNode,
      domEventHandlers,
      multiline: this.textArea
    })
  },
  watch: {
    value: {
      immediate: true,
      async handler(newVal) {
        if (newVal instanceof InputEvent) {
          return
        }
        if (newVal instanceof ASScriptHolder) {
          this.buildSections(`{{${newVal.value}}}`)
        } else if ( newVal instanceof ASHolder ) {
          this.buildSections(newVal.value)
        } else {
          this.buildSections(newVal)
        }
      }
    }
  },
  methods: {
    async buildSections(newVal) {
      let model = newVal
        if (Array.isArray(newVal)) {
          model = newVal.join(' ')
        }
        if (model != null && typeof model !== 'string') {
          model = model.toString()
        }
        const split = model?.split(/({{.+?}})/g) ?? []
        this.sections = split
          .filter(section => section.length > 0)
          .map(string => {
            const tokenProps = tokenPropsFromString(string, this.flow)
            return tokenProps ?? string
          })

        await this.$nextTick()

        // Replacing whole content with the parsing of the newly rendered content node
        this.editor.refreshContent()
    },
    insert(text) {
      this.editor.insertExpression(text)
      this.emitEditorContent()
    },
    insertToken(tokenProps) {
      this.editor.insertToken(tokenProps)
      this.emitEditorContent()
    },
    sanitize(text) {
      if (isExpression(text)) {
        return text
      }
      if (this.blockType === 'decimalInput' || this.blockType === 'currencyInput' || this.blockType === 'geoLocationInput' ) {
        const parse = parseFloat(text)
        return Number.isNaN(parse) ? null : parse
      }
      if (this.blockType === 'integerInput') {
        const parse = parseInt(text)
        return Number.isNaN(parse) ? null : parse
      }
      return text.length > 0 ? text : null
    },
    emitEditorContent() {
      const content = this.editor.getContentAsText()
      const sanitizedContent = this.sanitize(content)
      const holder = holderFromString(sanitizedContent)
      this.externalModel = holder
      if (sanitizedContent === this.value) {
        this.buildSections(sanitizedContent)
      }
      this.$emit('blur')
    },
    focus() {
      this.editor.focus()
    },
    showPath(event, expression) {
      if (this.pathMenuTimeout != null) {
        clearTimeout(this.pathMenuTimeout)
      }
      const visitor = new PathDisplayVisitor(this.flow)

      const ast = ASParser.as_parse_(expression)
      visitor.as_visit_(ast)
      this.path = visitor.pathString
      this.pathMenuX = event.clientX
      this.pathMenuY = event.clientY + 24
      this.pathMenuTimeout = setTimeout(() => this.pathMenu = true, 400)
    },
    hidePath() {
      if (this.pathMenuTimeout != null) {
        clearTimeout(this.pathMenuTimeout)
      }
      this.pathMenu = false
    }
  },
  components: {
    Token
  }
}
</script>

<style scoped>
.template-input-wrapper {
  position: relative;
}
.label {
  position: absolute;
  top: 12px;
  left: 12px;
  pointer-events: none;
  z-index: 1;
}
.error-icon {
  position: absolute;
  top: 10px;
  right: 12px;
  z-index: 1;
}

.error-tooltip {
  background-color: white;
  color: red;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.06), 0px 8px 10px 1px rgba(0, 0, 0, 0.06), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
}

::v-deep.input > div{
  min-height: 40px;
  padding-bottom: 6px;
  padding-top: 6px;
  padding-left: 6px;
  border: solid var(--border-color) 1px;
  border-radius: 4px;
  background: white;
  white-space: pre;
  overflow: auto;
  -webkit-user-select: auto;
  user-select: all;
}

::v-deep.textArea > div{
  min-height: 80px;
  resize: vertical;
}

::v-deep.input p {
  margin: 0px;
}

.hidden-content {
  display: none;
}

.top-label {
  opacity: 1;
  transition: opacity 0.2s;
}

.invisible {
  opacity: 0;
}
</style>