<template>
  <BaseBlock :block="block" v-on="$listeners" :editorMode="editorMode">
    <template v-slot:menu>
      <v-menu v-if="stateComponent && editorMode" v-model="settingsMenu" :close-on-content-click="false">
        <template v-slot:activator="{on}">
          <MenuItem
            icon="mdi-cog-outline"
            :text="$t('blocks.blockSettings')"
            v-on="on"
          />
        </template>
        <v-sheet class="pa-3">
          <component
            class="background"
            :is="stateComponent"
            v-model="statefulTypeModel"
          />
          <div class="d-flex justify-end">
            <v-btn text color="primary" @click="submitType">{{$t('dialogs.okButton')}}</v-btn>
          </div>
        </v-sheet>
      </v-menu>
    </template>
    <template v-slot:default="{showControls}">
      <BaseInputBlock :block="block" :editorMode="editorMode" :showControls="showControls">
        <CellAsBlock :block="block" v-model="inputValue" @blur="updateBlock"/>
      </BaseInputBlock>
    </template>
  </BaseBlock>
</template>

<script>
import BaseBlock from '@/components/block/BaseBlock.vue'
import BaseInputBlock from '@/components/block/BaseInputBlock.vue'
import FormFieldStyles from '@/constants/formFieldStyles.js'
import CellAsBlock from './CellAsBlock.vue'
import Block from '@/store/models/blocks/Block'
import MenuItem from '@/components/MenuItem.vue'
import { createHolder } from '@/apptivescript/model'

export default {
  props: {
    block: {
      validator: function (value) {
        return value instanceof Block
      }
    },
    editorMode: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      settingsMenu: false,
      statefulTypeModel: undefined
    }
  },
  computed: {
    fieldStyle() {
      return FormFieldStyles.outlined
    },
    inputValue: {
      get() {
        const holder = createHolder(this.block.inputValue)
        return holder?.value
      },
      set(value) {
        this.block.inputValue = createHolder(value)
      }
    },
    stateComponent() {
      return this.block.columnType?.state?.component
    }
  },
  watch: {
    block: {
      immediate: true,
      handler(newVal) {
        if (newVal == null) {
          this.statefulTypeModel = undefined
        } else {
          this.statefulTypeModel = this.block.columnType?.state?.initialisation(this.block.valueType)
        }
      }
    }
  },
  methods: {
    updateBlock() {
      this.$emit('updateBlock')
    },
    submitType() {
      this.block.patchType(this.statefulTypeModel)
      this.settingsMenu = false
    }
  },
  components: {
    CellAsBlock,
    BaseBlock,
    BaseInputBlock,
    MenuItem
  }
}
</script>

<style lang="css" scoped>
.outlined>>>.v-input__slot {
  border: 1px solid #D0D5DD !important;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
}
</style>