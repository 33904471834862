import store from '../store'
import i18n from '@/plugins/i18n'
import { columnTypes } from '../../constants/columnTypes'
import { parseFilter } from '@/filter/filter.js'
import CardViewBase from './CardViewBase'

// Version 1 :
const STATE_FIELD_ID = 'stateFieldId'
// Version 2 :
const IS_KANBAN_STATE = 'isKanbanState'
// Version 2.1 no mgration needed :
// adding card settinga to order and hide field on a kanbna card
const KANBAN_CARD_POSITION = 'kanbanCardPosition'

const MAX_COLUMN_SIZE = 50

export default class KanbanView extends CardViewBase {
  constructor(data) {
    super(data)
    this.migrate()
  }

  migrate() {
    if (this.version === 1) {
      const stateFieldId = this.properties[STATE_FIELD_ID]
      this.setFieldProperty(stateFieldId, IS_KANBAN_STATE, true)
      this.version = 2
      store().dispatch('AGPatchStatefulViewOperation', {
        statefulView: this,
        properties: this.properties,
        slotProperties: this.slotProperties
      })
    }
  }

  get cardPositionKey() {
    return KANBAN_CARD_POSITION
  }

  isOfAcceptedType(field) {
    return field.hasOneOfTypes([columnTypes.enum])
  }

  get stateFieldId() {
    return this.getFieldProperty(IS_KANBAN_STATE)
  }

  reload() {
    return store().dispatch('AGReadStatefulViewOperation', this.uri)
  }

  async setStateFieldId(fieldId) {
    this.deleteFieldProperty(this.stateFieldId, IS_KANBAN_STATE)
    this.setFieldProperty(fieldId, IS_KANBAN_STATE, true)

    // If the field was hidden, we need to add it to the shown fields first
    if (this.parentGrid.hiddenFields.some(field => field.id === fieldId)) {
      const parentFieldIds = this.parentGrid.parentGrid().fields.map(field => field.id)
      const fieldIds = this.parentGrid.fields.map(field => field.id)
      const newFieldIds = parentFieldIds.filter(id => id === fieldId || fieldIds.includes(id))
      await this.parentGrid.updateShownFields(newFieldIds)
    }

    return store().dispatch('AGPatchStatefulViewOperation', {
      statefulView: this,
      slotProperties: this.slotProperties
    })
      .then(() => this.reload())
  }

  entityParams() {
    if (!this.stateFieldId) {
      return undefined
    }
    const stateField = this.parentGrid.fields.find(field => field.id === this.stateFieldId)
    const states = stateField.type.options
    const filters = states.map(state => {
      return parseFilter({
        [ this.stateFieldId ]: state
      })
    })
    // Because of the extended enum,
    // The "Unstacked" column can contain all entities with a value
    // for the stateField that is not in the states
    filters.push(parseFilter({
      $and: states.map(state => ({
        $not: {
          [this.stateFieldId]: { $eq: state }
        }
      }))
    }))
    return filters.map(filter => ({
      filter,
      pageSize: MAX_COLUMN_SIZE
    }))
  }

  kanbanModel(grid, includingUnsortedEntities) {
    if (!this.stateFieldId) {
      return
    }
    const statesIndex = grid.fields.findIndex(field => field.id === this.stateFieldId)
    const stateField = grid.fields[statesIndex]
    var states = grid.fields[statesIndex].type.options

    if (includingUnsortedEntities) {
      states = [null, ...states]
    }
    const columns = states.map((state) => {
      return {
        title: state || i18n.t('kanban.unstacked'),
        stateField: stateField,
        state: state,
        tasks: grid.entities
          .filter((entity) => {
            // The null state corresponding to the "Unstacked" column
            // can contain all entities with a value
            // for the stateField that is not in the states
            if ((state == null && !states.includes(entity.fields[statesIndex]))) {
              return true
            }
            return entity.fields[statesIndex] === state
          })
          .map((entity) => {
            return {
              stateField: stateField,
              entity: entity,
              attributes: entity.fields.map((entityField, index) => {
                return {
                  key: grid.fields[index].name,
                  value: grid.displayFormat(entityField, index),
                  fieldValue: entityField,
                  property: grid.fields[index].columnType,
                  fieldId: grid.fields[index].id
                }
              })
              .filter( attr => this.fieldIsShown( attr.fieldId )).sort( (a, b) => this.fieldPostion(a.fieldId) - this.fieldPostion(b.fieldId)),

            }
          })
      }
    })
    return columns
  }
}