import ASScriptHolder from '@/apptivescript/model/ASScriptHolder'
import ASTemplateHolder from '@/apptivescript/model/ASTemplateHolder'
import ASValueHolder from '@/apptivescript/model/ASValueHolder'

export function createHolder(data) {
  if (data == null) {
    return null
  }

  if (Array.isArray(data) && data.length === 0) {
    return null
  }

  if (typeof data === 'string' && data.length === 0) {
    return null
  }

  if (typeof data !== 'object') {
    return new ASValueHolder({_value: data})
  }

  let holder
  if ('_template' in data) {
    holder = new ASTemplateHolder(data)
  } else if ('_script' in data) {
    holder = new ASScriptHolder(data)
  } else if ('_value' in data) {
    holder = new ASValueHolder(data)
  } else {
    holder = new ASValueHolder({_value: data})
  }
  if (typeof holder.value === 'object') {
    for(let key in holder.value) {
      holder.value[key] = createHolder(holder.value[key])
    }
  }
  return holder
}

export function holderFromString(data) {
  if (typeof data !== 'string') {
    return createHolder(data)
  }

  const expressionRegex = /({{.+?}})/g
  const hasExpression = expressionRegex.test(data)
  if (!hasExpression) {
    return new ASValueHolder({_value: data})
  }
  const split = (data?.split(expressionRegex) ?? []).filter(item => item.length > 0)
  if (split.length > 1) {
    return new ASTemplateHolder({_template: data})
  }
  const expression = data.slice(2, data.length - 2)
  return new ASScriptHolder({_script: expression})
}