<template>
  <BaseBlock :block="block" v-on="$listeners" :editorMode="editorMode">
    <div
      ref="contenteditable"
      :contenteditable="contenteditable"
      class="paragraph-text-field"
      :placeholder="$t('blocks.emptyParagraph')"
      @blur="updateBlock"
      @keydown.enter.prevent="addBlockAfter"
      @keydown.backspace="deleteBlock"
    >{{ text }}</div>
  </BaseBlock>
</template>

<script>
import BaseBlock from '@/components/block/BaseBlock.vue'
import ParagraphBlock from '@/store/models/blocks/ParagraphBlock'

  export default {
    props: {
      block: {
        type: ParagraphBlock
      },
      editorMode: {
        type: Boolean,
        default: () => false
      }
    },
    computed: {
      contenteditable() {
        return this.editorMode ? 'plaintext-only' : 'false'
      },
      text: {
        get() {
          return this.block.text
        },
        set(newVal) {
          this.block.text = newVal
        }
      }
    },
    watch: {
      text: {
        immediate: true,
        async handler(newVal) {
          await this.$nextTick()
          this.$refs.contenteditable.textContent = newVal
        }
      }
    },
    methods: {
      updateBlock() {
        this.text = this.$refs.contenteditable.textContent
        this.$emit('updateBlock')
      },
      addBlockAfter() {
        this.$emit('addBlockAfter')
      },
      deleteBlock() {
        if (!this.$refs.contenteditable.textContent) {
          this.$emit('delete')
        }
      },
      focus() {
        const el = this.$refs.contenteditable
        const selection = window.getSelection()
        const range = document.createRange()
        selection.removeAllRanges()
        range.selectNodeContents(el)
        range.collapse(false)
        selection.addRange(range)
        el.focus()
      }
    },
    components: {
      BaseBlock
    }
  }
</script>

<style lang="scss" scoped>
.paragraph-text-field {
  outline: unset;
  width: 100%;
  overflow: hidden;
  height: fit-content;
}

[contenteditable=plaintext-only]:empty:not(:focus):before{
  content: attr(placeholder);
  pointer-events: none;
  color: lightgray;
  display: block; /* For Firefox */
}
</style>