<template>
  <div
    v-if="virtualGrid"
    no-gutters
    class="headerCell d-flex align-center  pa-1"
    :style="style"
    @contextmenu.prevent="openSettings"
  >
      <v-icon v-if="!readonly" class="mr-2 ml-1" small>{{ icon }}</v-icon>
    <EditableText
      :readonly="readonly || disableEditableName"
      class="full-width textOverflow"
      textClass="text-subtitle-2 grey--text text--darken-2"
      :text="field.name"
      @submit="renameField"
      data-testid="headerCell-text"
    />
    <div class="ml-auto" v-if="!readonly">
      <ColumnSettingsMenu
        ref="settingsMenu"
        :virtualGrid="virtualGrid"
        :field="field"
        :index="index"
      />
    </div>
    <div
      class="resize-area"
      @mousedown="startResize"
      v-if="!readonly"
    />
  </div>
</template>

<script>
import ColumnSettingsMenu from './ColumnSettingsMenu.vue'
import EditableText from '@/components/EditableText.vue'

export default {
  inject: ['canUpdateFieldName'],
  props: {
    field: null,
    virtualGrid: null,
    readonly: {
      type: Boolean,
      default: false
    },
    width: null,
    index: null,
  },
  data() {
    return {
      resizePosition: undefined
    }
  },
  mounted() {
    document.addEventListener('mouseup', this.stopResize, false)
  },
  beforeDestroy() {
    document.removeEventListener('mousemove', this.resize, false)
    document.removeEventListener('mouseup', this.stopResize, false)
  },
  computed: {
    icon() {
      var icon = null
      try {
        icon = this.field.columnType.typeIcon
      } catch (error) {
        this.$apptiveErrorReporter.captureException(error)
        icon = 'mdi-progress-download'
      }
      return icon
    },
    style() {
      return {
        width: this.width ? `${this.width}px` : undefined
      }
    },
    disableEditableName() {
      if (!this.canUpdateFieldName) {
        return true
      }
      return !this.canUpdateFieldName()
    }
  },
  methods: {
    resize(event) {
      const dx = event.x - this.resizePosition
      this.resizePosition = event.x
      this.virtualGrid.changeWidth(this.field.id, dx)
    },
    startResize(event) {
      this.resizePosition = event.x
      document.addEventListener('mousemove', this.resize, false)
    },
    stopResize() {
      document.removeEventListener('mousemove', this.resize, false)
    },
    renameField(newName) {
      if (this.field.name == newName) return
      return this.virtualGrid.renameColumn(this.field.name, newName)
    },
    openSettings() {
      this.$refs.settingsMenu.open()
    }
  },
  components: {
    ColumnSettingsMenu,
    EditableText
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.headerCell {
  border-style: none solid none none;
  border-width: 1px;
  border-color: #d3d2d4;
  position: relative;
  height: 100%;
}
.textOverflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.full-width {
  width: 100%
}

.resize-area {
  position: absolute;
  top: 0;
  right: 0;
  width: 4px;
  height: 100%;
  cursor: ew-resize;
}

</style>
